import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../libs/generalSettings";

export const encryptJson = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.customKey.toString()
  ).toString();
  return encryptedData;
};
export const encrypt = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(
    data,
    process.env.customKey.toString()
  ).toString();
  return encryptedData;
};
export const decrypt = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(
    encryptedData,
    process.env.customKey.toString()
  );
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
};
