function parseData(data) {
  const formData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  return formData;
}

function request(url, data = false, method = "GET", type = "FORM_DATA") {
  return new Promise(async (resolve, reject) => {
    try {
      const options = {
        method,
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (data) {
        options.headers.Authorization = data;
      }

      if (data && method === "POST") {
        if (data.token != null) {
          options.headers.Authorization = data.token;

          options.body =
            type === "JSON" ? JSON.stringify(data) : parseData(data);
        } else {
          options.headers.Authorization = data;
          options.body =
            type === "JSON" ? JSON.stringify(data) : parseData(data);
        }
      }

      const response = await fetch(url, options);
      const result = await response.json();

      if (response.ok) {
        resolve(result);
      } else {
        reject(result);
      }
    } catch (error) {
      return error;
    }
  });
}

export const post = (url, data) => request(url, data, "POST");
export const postJSON = (url, data) => request(url, data, "POST", "JSON");
export const get = (url, data) => request(url, data);
