import Link from "next/link";
import { useSelector, useDispatch } from "react-redux";
import Language from "../../locales/GetLanguageResourceResponse.json";
import { encryptJson, decrypt } from "../../libs/encryption";
import { useEffect, useState } from "react";
import { API_BASE_URL, API_BASEMOBIL_URL } from "../../libs/generalSettings";
import { get, postJSON, post } from "../../libs/services/request";
import { MetinKisalt, SeoUrlRegex } from "../../libs/utils";
import {
  decrementCartAllItemCount,
  decrementCartItemCount,
} from "../../redux/stores/basket";
import { logout } from "../../redux/stores/auth";
import { useRouter } from "next/router";
import Image from "next/image";

const Basket = () => {
  const [sepetim, setSepetim] = useState([]);
  const [sepetCount, setSepetCount] = useState(0);
  const languageStore = useSelector((state) => state.language);
  const isLoggin = decrypt(useSelector((state) => state.auth.loggedIn));
  const userId = decrypt(useSelector((state) => state.auth.user));
  const token = decrypt(useSelector((state) => state.auth.authorization));
  const basket = decrypt(useSelector((state) => state.basket));
  const cleanedToken = token.replace(/"/g, "");
  const dispatch = useDispatch();
  const router = useRouter();
  useEffect(() => {
    if (isLoggin) {
      setSepetim([]);
      setSepetCount(0);
      (async () => {
        try {
          var uid = JSON.parse(userId);

          await get(
            `${API_BASE_URL}/getSepettekiUrunler?musteriId=${uid.fltMusteriId}&dil=${languageStore}`,
            cleanedToken
          ).then((result) => {
            {
              if (result.sonuc === true) {
                setSepetCount(result.data.length);
                setSepetim(result);
              } else {
                if (
                  result.sonucAciklama ===
                  "Oturum hatası. Filateli müşteri uyuşmuyor."
                ) {
                  setSepetCount(0);
                  dispatch(decrementCartAllItemCount(0));
                  dispatch(logout());

                  router.push("/login");
                }
                if (
                  result.sonucAciklama ===
                  "Oturumunuzun suresi dolmustur. Lutfen tekrar giris yapiniz."
                ) {
                  setSepetCount(0);
                  dispatch(decrementCartAllItemCount(0));

                  dispatch(logout());

                  router.push("/login");
                }
              }
            }
          });
        } catch (error) {}
      })();
    } else {
      setSepetim([]);
      setSepetCount(0);
    }
  }, [basket, languageStore, isLoggin]);
  useEffect(() => {
    if (isLoggin) {
      setSepetim([]);

      (async () => {
        try {
          var uid = JSON.parse(userId);

          await get(
            `${API_BASE_URL}/getSepettekiUrunler?musteriId=${uid.fltMusteriId}&dil=${languageStore}`,
            cleanedToken
          ).then((result) => {
            {
              if (result.sonuc === true) {
                setSepetCount(result.data.length);
                setSepetim(result);
              } else {
                if (
                  result.sonucAciklama ===
                  "Oturumunuzun suresi dolmustur. Lutfen tekrar giris yapiniz."
                ) {
                  setSepetCount(0);
                  dispatch(decrementCartAllItemCount(0));
                  dispatch(logout());
                  router.push("/login");
                }
              }
            }
          });
        } catch (error) {}
      })();
    } else {
      setSepetim([]);
      setSepetCount(0);
    }
  }, [sepetCount, languageStore, isLoggin]);
  const SepettenSil = async (product) => {
    if (isLoggin) {
      var uid = JSON.parse(userId);
      const params = {
        urunId: product.urun.urunId,
        musteriId: uid.fltMusteriId,
        adet: 0,
        token: cleanedToken,
      };
      await postJSON(`${API_BASE_URL}/DeleteFromShoppingCart`, params).then(
        (result) => {
          {
            if (result.sonuc === true) {
              let timerInterval;
              Swal.fire({
                title: Language[languageStore].urunSepettenCikarildi,
                html: "<b>" + product.urun.urunAd + "</b>",
                icon: "success",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
              dispatch(decrementCartItemCount());
              setSepetCount(sepetCount - 1);
              // SepettekiUrunler();
            } else {
              Swal.fire({
                title: Language[languageStore].urunSepettenCikarilmadi,
                html: "<b>" + product.urun.urunAd + "</b>",
                icon: "error",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
            }
          }
        }
      );
    } else {
      router.push("/login");
    }
  };
  const handleLogout = async () => {
    post(`${API_BASEMOBIL_URL}/users/logout`, cleanedToken).then((result) => {
      {
        setSepetCount(0);
        dispatch(decrementCartAllItemCount(0));
        dispatch(logout());
        router.push("/login");
      }
    });
  };
  const accountClose = (type) => {
    if (type === 1) {
      $(".dropdown-account").removeClass("dropdown-open");
    } else if (type === 2) {
      $(".dropdown-cart").removeClass("dropdown-open");
    }
  };
  return (
    <div className="header-shop">
      <div className="d-inline-block box-dropdown-cart">
        <span
          onClick={() => accountClose(2)}
          className="font-lg icon-list icon-account"
        >
          <span>{Language[languageStore].menuHesabim}</span>
        </span>
        <div className="dropdown-account">
          <ul>
            {isLoggin ? (
              <>
                {" "}
                <li>
                  <Link href="/user/">
                    <i className="fa fa-solid fa-user"></i>&nbsp;
                    {Language[languageStore].menuKullaniciPaneli}
                  </Link>
                </li>
                <li>
                  <Link href="/user/orders">
                    <i className="fa fa-solid fa-heart"></i>&nbsp;
                    {Language[languageStore].menuSiparislerim}
                  </Link>
                </li>
                <li>
                  <Link href="/user/basket">
                    <i className="fa fa-solid fa-star"></i>&nbsp;
                    {Language[languageStore].menuSepetim}
                  </Link>
                </li>
                <li>
                  <Link href="/user/edit">
                    <i className="fa fa-solid fa-edit"></i>&nbsp;
                    {Language[languageStore].menuKullaniciBilgilerim}
                  </Link>
                </li>
                <li>
                  <Link href="/user/recovery-password">
                    <i className="fa fa-solid fa-key"></i>&nbsp;
                    {Language[languageStore].menuSifremiDegistir}
                  </Link>
                </li>
                <li>
                  <a href="#" onClick={() => handleLogout()}>
                    <i className="fa fa-solid fa-lock"></i>&nbsp;
                    {Language[languageStore].menuCikisYap}
                  </a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link href="/login">
                    {Language[languageStore].menuGirisYap}
                  </Link>
                </li>
                <li>
                  <Link href="/register">
                    {Language[languageStore].menuKayitOl}
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <span
          onClick={() => accountClose(1)}
          className="font-lg icon-list icon-cart"
        >
          <span>{Language[languageStore].menuSepetim}</span>
          <span className="number-item font-xs">{sepetCount}</span>
        </span>
        <div className="dropdown-cart">
          {sepetCount > 0 ? (
            <>
              <div className="basket-container">
                {isLoggin == "true" ? (
                  sepetim.data?.map((product, index) => {
                    return (
                      <div key={index} className="item-cart mb-20">
                        <div className="cart-image">
                          <img
                            src={product.urun.resimLink}
                            alt={product.urun.urunAd}
                            width={50}
                            height={50}
                          />
                          <div className="col-md-12 text-start">
                            <button
                              className="btn-sm btn-danger font-xs basket-btn-trash text-center"
                              onClick={() => SepettenSil(product)}
                            >
                              <i className="fa fa-solid fa-trash"></i>&nbsp;
                              {Language[languageStore].sepetUrunSil}
                            </button>
                          </div>
                        </div>
                        <div className="cart-info">
                          <Link
                            href={`/urunler/${SeoUrlRegex(
                              product.urun.urunAd
                            )}/${product.urun.urunId}`}
                            title={product.urun.urunAd}
                            className="font-sm-bold color-brand-3"
                          >
                            {product.urun.urunAd}
                          </Link>

                          <p>
                            <span className="color-brand-2 font-sm-bold">
                              <span className="font-xs color-gray-500">
                                {Language[languageStore].sepettekiAdeti} (
                                {product.adet})
                              </span>{" "}
                              <br /> {product.adet} x {product.urun.ucret}
                              &nbsp;TL
                            </span>
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="sepet-urun-yok">
                    {" "}
                    <div className="row mt-15">
                      <div className="col-12 text-start">
                        <Link className="btn btn-cart w-auto" href="/login">
                          {Language[languageStore].sepetUrunBulunamadi}
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <>
                {isLoggin && (
                  <>
                    <div className="border-bottom pt-0 mb-15" />
                    <div className="cart-total">
                      <div className="row">
                        <div className="col-6 text-start">
                          <span className="font-sm-bold color-brand-3">
                            {Language[languageStore].hizmetBedeli}
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="font-md-bold color-brand-2">
                            {sepetim.hizmetBedeli} TL
                          </span>
                        </div>
                        <div className="border-bottom pt-0 mb-10" />

                        <div className="col-6 text-start">
                          <span className="font-sm-bold color-brand-3">
                            {" "}
                            {Language[languageStore].sepetTutari}
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="font-md-bold color-brand-2">
                            {sepetim.sepetTutari} TL
                          </span>
                        </div>
                        <div className="border-bottom pt-0 mb-10" />

                        <div className="col-6 text-start">
                          <span className="font-sm-bold color-brand-3">
                            {" "}
                            {Language[languageStore].toplamTutar}
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="font-md-bold color-brand-2">
                            {sepetim.toplamTutar} TL
                          </span>
                        </div>
                      </div>

                      <div className="row mt-15">
                        <div className="col-md-12">
                          <Link className="btn btn-cart" href="/user/basket/">
                            {" "}
                            {Language[languageStore].menuSepetim}
                          </Link>
                        </div>
                        {/* <div className="col-6">
                          <a className="btn btn-buy w-auto" href="#">
                            {Language[languageStore].odeme}
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </>
                )}
              </>
            </>
          ) : (
            <>
              {" "}
              <div className="sepet-urun-yok">
                {" "}
                <div className="row mt-15">
                  <div className="col-12 text-start">
                    <Link className="btn btn-cart w-auto" href="/">
                      {Language[languageStore].sepetUrunBulunamadiGirisYapilmis}
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Basket;
