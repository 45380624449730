import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { isInjectionValid } from "../libs/utils";
import { useEffect } from "react";
import { store, persistor } from "../redux/";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import { useRouter } from "next/router";

export default function App({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    import("../public/static/js/vendors/slick.js");
    import("../public/static/js/vendors/noUISlider.js");
    import("../public/static/js/main.js");
    import("../public/static/js/shop.js");
  });
  useEffect(() => {
    if (isInjectionValid(router.query.slug) === true) {
      router.push("/notfound");
      return;
    }
  }, []);
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <header>
            <Header />
          </header>
          <Component {...pageProps} />
          <footer className="footer">
            <Footer />
          </footer>
        </PersistGate>
      </Provider>
    </>
  );
}
