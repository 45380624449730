import { useSelector } from "react-redux";
import Link from "next/link";
import Language from "../../../locales/GetLanguageResourceResponse.json";
import { LanguageType } from "../../../libs/enums";
import appStoreImg from "../../../public/static/imgs/template/appstore.png";
import googlePlayImg from "../../../public/static/imgs/template/google-play.png";
import paymentMethodImg from "../../../public/static/imgs/template/payment-method.png";
import Image from "next/image";

const Footer = () => {
  const languageStore = useSelector((state) => state.language);

  return (
    <>
      <div className="footer-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 width-25 mb-30">
              <h4 className="mb-30 color-gray-1000">
                {" "}
                {Language[languageStore].footerMenuBizeUlasin}
              </h4>
              <div className="font-md mb-20 color-gray-900">
                <strong className="font-md-bold">
                  {" "}
                  {Language[languageStore].genelAdres}:
                </strong>{" "}
                Hacıbayram Mahallesi Şehit Teğmen Kalmaz Cad. No:2 Ulus/
                Altındağ/ Ankara
              </div>
              <div className="font-md mb-20 color-gray-900">
                <strong className="font-md-bold">
                  {Language[languageStore].genelTelefon}:
                </strong>{" "}
                (0312) 509 57 33
              </div>
              <div className="font-md mb-20 color-gray-900">
                <strong className="font-md-bold">
                  {" "}
                  {Language[languageStore].genelFax}:
                </strong>{" "}
                (0312) 309 59 65
              </div>
              <div className="font-md mb-20 color-gray-900">
                <strong className="font-md-bold">
                  {" "}
                  {Language[languageStore].genelEposta}:
                </strong>{" "}
                <a href="mailto:filateli@ptt.gov.tr">filateli@ptt.gov.tr</a>
              </div>

              <div className="mt-30">
                <a
                  className="icon-socials icon-facebook"
                  target="_blank"
                  href="https://www.facebook.com/Ptt.Kurumsal"
                />
                <a
                  target="_blank"
                  className="icon-socials icon-instagram"
                  href="https://www.instagram.com/pttkurumsal/"
                />
                <a
                  target="_blank"
                  className="icon-socials icon-twitter"
                  href="https://twitter.com/PTTKurumsal"
                />
                <a
                  target="_blank"
                  className="icon-socials icon-linkedin"
                  href="https://tr.linkedin.com/company/ptt-a%C5%9F"
                />
              </div>
            </div>
            <div className="col-lg-3 width-20 mb-30">
              <h4 className="mb-30 color-gray-1000">
                {" "}
                {Language[languageStore].footerMenuKategoriler}
              </h4>
              <ul className="menu-footer">
                <li>
                  <Link
                    className="font-sm"
                    href={
                      languageStore === LanguageType.TR
                        ? "/kategoriler/anma-pulu/1001"
                        : "/kategoriler/commemorative-stamps/1001"
                    }>
                    {languageStore === LanguageType.TR
                      ? "Anma Pulu"
                      : "Commemorative Stamps"}
                  </Link>
                </li>
                <li>
                  <Link
                    className="font-sm"
                    href={
                      languageStore === LanguageType.TR
                        ? "/kategoriler/surekli-pul/1002"
                        : "/kategoriler/definitive-stamps/1002"
                    }>
                    {languageStore === LanguageType.TR
                      ? "Sürekli Pul"
                      : "Definitive Stamps"}
                  </Link>
                </li>
                <li>
                  <Link
                    className="font-sm"
                    href={
                      languageStore === LanguageType.TR
                        ? "/kategoriler/resmi-pul/1003"
                        : "/kategoriler/official-postage-stamps/1003"
                    }>
                    {languageStore === LanguageType.TR
                      ? "Resmi Pul"
                      : "Official Postage Stamps"}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 width-16 mb-30">
              <h4 className="mb-30 color-gray-1000">
                {Language[languageStore].footerMenuSayfalar}
              </h4>
              <ul className="menu-footer">
                <li>
                  <Link
                    href="/kurumsal/abone-islemleri"
                    className="active"
                    title={Language[languageStore].menuAboneIslemleri}>
                    {" "}
                    {Language[languageStore].menuAboneIslemleri}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/kurumsal/iptal-ve-iade-kosullari"
                    className="active"
                    title={Language[languageStore].menuIptalVeIadeKosullari}>
                    {" "}
                    {Language[languageStore].menuIptalVeIadeKosullari}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 width-16 mb-30">
              <h4 className="mb-30 color-gray-1000">
                {" "}
                {Language[languageStore].footerMenuBaglantilar}
              </h4>
              <ul className="menu-footer">
                <li>
                  <a target="_blank" href="https://www.ptt.gov.tr">
                    Ptt.gov.tr
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 width-23">
              <h4 className="mb-30 color-gray-1000">
                {" "}
                {Language[languageStore].footerMenuMobilUygulama}
              </h4>
              <div>
                <p className="font-md color-gray-900">
                  {languageStore === LanguageType.TR
                    ? "Mobil uygulamamızı Google Play & App Store üzeriden indirebilirsiniz."
                    : "Our mobile app Google Play & App Store You can download over."}
                </p>
                <div className="mt-20">
                  <a
                    target="_blank"
                    className="mr-10"
                    href="https://apps.apple.com/tr/app/ptt-filateli/id969066502">
                    <Image src={appStoreImg} alt="App Store" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.ptt.union"
                    target="_blank">
                    <Image src={googlePlayImg} alt="Google Play" />
                  </a>
                </div>
                <p className="font-md color-gray-900 mt-20 mb-10">
                  {languageStore === LanguageType.TR
                    ? "Güvenli Ödeme Metodları"
                    : "Secure Payment Methods"}
                </p>
                <Image src={paymentMethodImg} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex mt-3 justify-content-center">
              © 2024 PTT AŞ Tüm Hakları Saklıdır.{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
