import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Link from "next/link";
import { setLanguage } from "../../../../redux/stores/language";
import Language from "../../../../locales/GetLanguageResourceResponse.json";
import { LanguageType } from "../../../../libs/enums";
import FlagTurkiyeImg from "../../../../public/static/imgs/template/flag-turkiye.svg";
import FlagEnImg from "../../../../public/static/imgs/template/flag-en.svg";
import Image from "next/image";
const TopBar = () => {
  const dispatch = useDispatch();
  const handleLanguageChange = (newLanguage) => {
    dispatch(setLanguage(newLanguage));
  };
  const languageStore = useSelector((state) => state.language);

  return (
    <div className="topbar">
      <div className="container-topbar">
        <div className="menu-topbar-left d-none d-xl-block">
          <ul className="nav-small">
            <li>
              <Link
                className="font-xs"
                href="/kurumsal/iptal-ve-iade-kosullari"
                title={Language[languageStore].iptalVeIadeKosullari}>
                {Language[languageStore].iptalVeIadeKosullari}
              </Link>
            </li>
            <li>
              <Link
                className="font-xs"
                href="/kurumsal/bize-ulasin"
                title={Language[languageStore].bizeUlasin}>
                {Language[languageStore].bizeUlasin}
              </Link>
            </li>
          </ul>
        </div>
        <div className=""></div>
        <div className="menu-topbar-right">
          <span className="font-xs">
            <Link href="/">
              <strong>
                {" "}
                {Language[languageStore].filateliInternetSubesiTitle}
              </strong>
            </Link>
            <Link
              onClick={() => handleLanguageChange("tr")}
              className=""
              href="#"
              id="tr">
              <Image
                onClick={() => handleLanguageChange("tr")}
                src={FlagTurkiyeImg}
                alt="tr"
                title="Türkiye"
                id="tr"
              />{" "}
              {Language[languageStore].flagTitleTR}
            </Link>
            <Link
              onClick={() => handleLanguageChange("en")}
              className=""
              href="#"
              id="en">
              <Image
                onClick={() => handleLanguageChange("en")}
                src={FlagEnImg}
                alt="en"
                title="English"
                id="en"
              />{" "}
              {Language[languageStore].flagTitleEN}
            </Link>
          </span>

          {/* <div className="dropdown dropdown-language">
            <button
              className="btn dropdown-toggle"
              id="dropdownPage"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              data-bs-display="static">
              <span className="dropdown-right font-xs color-brand-3">
                <Image
                  src={
                    languageStore === LanguageType.TR
                      ? FlagTurkiyeImg
                      : FlagEnImg
                  }
                  alt="Dil Seçiniz"
                />

                {languageStore === LanguageType.TR
                  ? " Türkçe"
                  : languageStore === LanguageType.EN
                  ? " İngilizce"
                  : ""}
              </span>
            </button>
            <ul
              className="dropdown-menu dropdown-menu-light"
              aria-labelledby="dropdownPage"
              data-bs-popper="static">
              <li>
                <Link
                  onClick={() => handleLanguageChange("tr")}
                  className="dropdown-item"
                  href="#"
                  id="tr">
                  <Image
                    onClick={() => handleLanguageChange("tr")}
                    src={FlagTurkiyeImg}
                    alt="tr"
                    title="Türkiye"
                    id="tr"
                  />{" "}
                  {Language[languageStore].flagTitleTR}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => handleLanguageChange("en")}
                  className="dropdown-item"
                  href="#"
                  id="en">
                  <Image
                    onClick={() => handleLanguageChange("en")}
                    src={FlagEnImg}
                    alt="en"
                    title="English"
                    id="en"
                  />{" "}
                  {Language[languageStore].flagTitleEN}
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default TopBar;
