import { createSlice } from "@reduxjs/toolkit";
import { encryptJson, decrypt } from "../../libs/encryption";

const userSlice = createSlice({
  name: "auth",
  initialState: {
    loggedIn: false,
    user: {},
    authorization: "",
    userId: "",
  },
  reducers: {
    login: (state, action) => {
      state.loggedIn = encryptJson(true);
      state.user = encryptJson(action.payload.user);
      state.authorization = encryptJson(action.payload.authorization);
      state.userId = encryptJson(action.payload.user.fltMusteriId);
    },
    logout: (state) => {
      state.loggedIn = false;
      state.user = {};
      state.authorization = "";
      state.userId = "";
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
