import Link from "next/link";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SeoUrlRegex } from "../../../../libs/utils";
import Basket from "../../../Basket";
import { setLanguage } from "../../../../redux/stores/language";
import Language from "../../../../locales/GetLanguageResourceResponse.json";
import {
  API_BASE_URL,
  API_BASEMOBIL_URL,
} from "../../../../libs/generalSettings";
import { get, post } from "../../../../libs/services/request";
import { useRouter } from "next/router";
import { encryptJson, decrypt } from "../../../../libs/encryption";
import { logout } from "../../../../redux/stores/auth";
import logo from "../../../../public/static/imgs/logo.png";
import FlagTurkiyeImg from "../../../../public/static/imgs/template/flag-turkiye.svg";
import FlagEnImg from "../../../../public/static/imgs/template/flag-en.svg";
import Avatar from "../../../../public/static/imgs/template/ava_1.png";
import appStoreImg from "../../../../public/static/imgs/template/appstore.png";
import googlePlayImg from "../../../../public/static/imgs/template/google-play.png";
import Image from "next/image";
import { decrementCartAllItemCount } from "../../../../redux/stores/basket";

const Menu = () => {
  const [searchValue, setSearchValue] = useState("");
  const [GetKategoriler, setGetKategoriler] = useState([]);

  const [GetAnaKategoriler, setGetAnaKategoriler] = useState([]);

  const [aboneBilgileri, setAboneBilgileri] = useState(false);
  const [userBilgileri, setUserBilgileri] = useState([]);
  const [userLogin, setUserLogin] = useState(false);
  const [languages, setLanguages] = useState("tr");
  const languageStore = useSelector((state) => state.language);
  const token = decrypt(useSelector((state) => state.auth.authorization));
  const userId = decrypt(useSelector((state) => state.auth.user));
  const isLoggin = decrypt(useSelector((state) => state.auth.loggedIn));
  const cleanedToken = token.replace(/"/g, "");
  const router = useRouter();
  const dispatch = useDispatch();
  const handleLanguageChange = (newLanguage) => {
    dispatch(setLanguage(newLanguage));
  };

  useEffect(() => {
    setGetKategoriler([]);

    (async () => {
      await get(`${API_BASE_URL}/getKategoriler?dil=${languageStore}`).then(
        (result) => {
          if (result.sonuc === true) {
            setGetAnaKategoriler(result.data);

            var select = $(".select-active");
            // Mevcut seçenekleri temizle
            select.empty();

            // Placeholder için boş bir option ekleyin
            var placeholderOption = new Option(
              Language[languageStore].secimYapiniz,
              ""
            );
            select.append(placeholderOption);

            $(".select-active").select2({
              minimumResultsForSearch: Infinity,
              placeholder: Language[languageStore].secimYapiniz,
            });

            for (const item of result.data) {
              const option = new Option(item.urunaciklama, item.urunkod);
              select.append(option);
            }
            var tumUrunlerdeAraOptions = new Option(
              Language[languageStore].tumUrunlerdeAra,
              0
            );
            select.append(tumUrunlerdeAraOptions);
            select.trigger("change"); // select2 güncellenir
          }
        }
      );
    })();
  }, [languageStore]); // languageStore değiştiğinde bu useEffect tetiklenir

  const handleSubmit = (event) => {
    const selectElement = document.getElementById("search-category");
    const selectedIndex = selectElement.selectedIndex;
    const selectedOption = selectElement.options[selectedIndex];
    const selectedId = selectedOption.value;
    if (selectedId === "") {
      router.push(`/search/${searchValue}/0/`);
      setSearchValue("");
      event.preventDefault();
    } else {
      router.push(`/search/${searchValue}/${selectedId}/`);
      setSearchValue("");
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (isLoggin) {
      setUserLogin(true);
    } else {
      setUserLogin(false);
    }
  }, [isLoggin]);
  useEffect(() => {
    if (userLogin) {
      var uid = JSON.parse(userId);
      setUserBilgileri(uid);

      (async () => {
        await get(
          `${API_BASE_URL}/getAboneBilgileri/${uid.fltMusteriId}`,
          cleanedToken
        ).then((result) => {
          {
            if (result.sonuc === true) {
              if (result.data.aboneBilgileri != null) {
                setAboneBilgileri(true);
              }
            } else {
            }
          }
        });
      })();
    } else {
      setAboneBilgileri(false);
    }
  }, [userLogin]);

  const handleLogout = async () => {
    post(`${API_BASEMOBIL_URL}/users/logout`, cleanedToken).then((result) => {
      {
        dispatch(decrementCartAllItemCount(0));

        dispatch(logout());
        router.push("/login");
      }
    });
  };
  const menuClose = () => {
    const elements = document.getElementsByClassName(
      "burger-icon burger-icon-white"
    );

    // NodeList'i bir diziye dönüştürün
    const elementsArray = Array.from(elements);

    // Her bir öğe üzerinde class'ı kaldırın
    elementsArray.forEach((element) => {
      element.classList.remove("burger-close");
    });
    const elements1 = document.getElementsByClassName(
      "mobile-header-active mobile-header-wrapper-style perfect-scrollbar sidebar-visible"
    );

    // NodeList'i bir diziye dönüştürün
    const elementsArray1 = Array.from(elements1);

    // Her bir öğe üzerinde class'ı kaldırın
    elementsArray1.forEach((element1) => {
      element1.classList.remove("sidebar-visible");
    });
    kategoriMenuAc();
  };
  const kategoriMenuAc = (type) => {
    if (type === 1) {
      const kategoriUl = document.getElementById("mkategoriler");
      const kategoriLi = document.getElementById("mkategorilersub");
      if (kategoriLi.style.display === "block") {
        kategoriUl.classList.remove("active");
        kategoriLi.style.display = "none";
        return;
      }
      if (kategoriLi.style.display === "none") {
        kategoriUl.classList.add("active");
        kategoriLi.style.display = "block";
        return;
      }
    }
    if (type === 2) {
      const kurumsalKategoriUl = document.getElementById("mkurumsal");
      const kurumsalKategoriLi = document.getElementById("mkurumsalsub");
      if (kurumsalKategoriLi.style.display === "block") {
        kurumsalKategoriUl.classList.remove("active");
        kurumsalKategoriLi.style.display = "none";
        return;
      }
      if (kurumsalKategoriLi.style.display === "none") {
        kurumsalKategoriUl.classList.add("active");
        kurumsalKategoriLi.style.display = "block";
        return;
      }
    }
    if (type === undefined) {
      const kategoriUl = document.getElementById("mkategoriler");
      kategoriUl.classList.remove("active");

      const kategoriLi = document.getElementById("mkategorilersub");
      kategoriLi.style.display = "none";

      const kurumsalKategoriUl = document.getElementById("mkurumsal");
      kurumsalKategoriUl.classList.remove("active");

      const kurumsalKategoriLi = document.getElementById("mkurumsalsub");
      kurumsalKategoriLi.style.display = "none";
      return;
    }
    //kurumsalMenuAc();
  };
  const kurumsalMenuAc = () => {};
  return (
    <>
      <header className="header sticky-bar">
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <Link className="d-flex" href="/">
                  <Image
                    alt="Ptt-Filateli"
                    src={logo}
                    width={200}
                    height={115}
                    style={{ objectFit: "cover" }}
                  />
                </Link>
              </div>

              <div className="header-search">
                <div className="box-header-search">
                  <form onSubmit={handleSubmit} className="form-search">
                    <div className="box-category">
                      <select
                        id="search-category"
                        className="select-active select2-hidden-accessible"
                      >
                        {" "}
                        {GetKategoriler &&
                          GetKategoriler?.map((category, index) => {
                            return (
                              <option
                                value={category.urunaciklama}
                                id={category.urunkod}
                                key={index}
                              >
                                {category.urunaciklama}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="box-keysearch">
                      <input
                        id="search"
                        type="text"
                        value={searchValue}
                        required
                        onChange={(e) => setSearchValue(e.target.value)}
                        className="form-control font-xs"
                        placeholder={Language[languageStore].aramaText}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="header-nav">
                <nav className="nav-main-menu d-none d-xl-block">
                  <ul className="main-menu">
                    <li>
                      <Link
                        href="/"
                        className="active"
                        title={Language[languageStore].menuAnasayfa}
                      >
                        {" "}
                        {Language[languageStore].menuAnasayfa}
                      </Link>
                    </li>
                    <li className="has-children">
                      <Link
                        href="#"
                        className=""
                        title={Language[languageStore].menuUrunler}
                      >
                        {" "}
                        {Language[languageStore].menuUrunler}
                      </Link>
                      <ul className="sub-menu two-col">
                        {GetAnaKategoriler &&
                          GetAnaKategoriler?.map((category, index) => {
                            return (
                              <li key={index}>
                                <Link
                                  href={`/kategoriler/${SeoUrlRegex(
                                    category.urunaciklama
                                  )}/${category.urunkod}`}
                                  title={category.urunaciklama}
                                >
                                  {category.urunaciklama}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                    <li className="has-children">
                      <Link
                        href="#"
                        className="active"
                        title={Language[languageStore].menuKurumsal}
                      >
                        {" "}
                        {Language[languageStore].menuKurumsal}
                      </Link>
                      <ul className="sub-menu two-col">
                        <li>
                          <Link
                            href="/kurumsal/abone-islemleri"
                            className="active"
                            title={Language[languageStore].menuAboneIslemleri}
                          >
                            {" "}
                            {Language[languageStore].menuAboneIslemleri}
                          </Link>
                        </li>
                        <li>
                          <Link
                            href="/kurumsal/iptal-ve-iade-kosullari"
                            className="active"
                            title={
                              Language[languageStore].menuIptalVeIadeKosullari
                            }
                          >
                            {" "}
                            {Language[languageStore].menuIptalVeIadeKosullari}
                          </Link>
                        </li>
                        {languageStore === "tr" ? (
                          <>
                            <li>
                              <Link
                                href="/kurumsal/filateli-dernek-federasyonu"
                                className="active"
                                title={
                                  Language[languageStore].menuDernekFederasyon
                                }
                              >
                                {" "}
                                {Language[languageStore].menuDernekFederasyon}
                              </Link>
                            </li>
                            <li>
                              <Link
                                href="/docs/2016_filateliterimler.pdf"
                                className="active"
                                target="_blank"
                                title={
                                  Language[languageStore].menuFilateliTerimler
                                }
                              >
                                {" "}
                                {Language[languageStore].menuFilateliTerimler}
                              </Link>
                            </li>
                            <li>
                              <Link
                                href="/kurumsal/filateli-egitim-seminerleri"
                                className="active"
                                title={
                                  Language[languageStore]
                                    .menuFilateliEgitimSeminer
                                }
                              >
                                {" "}
                                {
                                  Language[languageStore]
                                    .menuFilateliEgitimSeminer
                                }
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                    </li>

                    {isLoggin && aboneBilgileri ? (
                      <>
                        <li>
                          <Link
                            href="/user/subscription"
                            className="active"
                            title={Language[languageStore].menuAbonelikPaneli}
                          >
                            {" "}
                            {Language[languageStore].menuAbonelikPaneli}
                          </Link>
                        </li>
                      </>
                    ) : (
                      <li>
                        <Link
                          href="/kurumsal/bize-ulasin/"
                          className="active"
                          title={Language[languageStore].menuBizeUlasin}
                        >
                          {" "}
                          {Language[languageStore].menuBizeUlasin}
                        </Link>
                      </li>
                    )}
                  </ul>
                </nav>
                <div className="burger-icon burger-icon-white">
                  <span className="burger-icon-top" />
                  <span className="burger-icon-mid" />
                  <span className="burger-icon-bottom" />
                </div>
              </div>
              <Basket />
            </div>
          </div>
        </div>
      </header>
      <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="mobile-logo">
              <a className="d-flex" href="#">
                <Image alt="PTT-Filateli" src={logo} />
              </a>
            </div>
            <div className="perfect-scroll">
              <div className="language-mobil">
                <a href="#" onClick={() => handleLanguageChange("tr")} id="tr">
                  <span id="tr" className="font-xs color-brand-3">
                    <Image src={FlagTurkiyeImg} alt="Türkçe" id="tr" />{" "}
                    {Language[languageStore].flagTitleTR}
                  </span>
                </a>

                <a href="#" onClick={() => handleLanguageChange("en")} id="en">
                  <span id="en" className="font-xs color-brand-3">
                    <Image src={FlagEnImg} alt="English" id="en" />{" "}
                    {Language[languageStore].flagTitleEN}
                  </span>
                </a>
              </div>
              <div className="mobile-menu-wrap mobile-header-border">
                <nav className="mt-15">
                  <ul className="mobile-menu font-heading">
                    <li>
                      <Link
                        href="/"
                        className="active"
                        onClick={() => menuClose()}
                        title={Language[languageStore].menuAnasayfa}
                      >
                        {" "}
                        {Language[languageStore].menuAnasayfa}
                      </Link>
                    </li>
                    <li className="has-children" id="mkategoriler">
                      <Link
                        href="#"
                        onClick={() => kategoriMenuAc(1)}
                        title={Language[languageStore].menuUrunler}
                      >
                        {" "}
                        {Language[languageStore].menuUrunler}
                      </Link>
                      <ul className="sub-menu" id="mkategorilersub">
                        {GetAnaKategoriler.map((category, index) => {
                          return (
                            <li key={index}>
                              <Link
                                onClick={() => menuClose()}
                                href={`/kategoriler/${SeoUrlRegex(
                                  category.urunaciklama
                                )}/${category.urunkod}`}
                                title={category.urunaciklama}
                              >
                                {category.urunaciklama}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                    <li className="has-children" id="mkurumsal">
                      <Link
                        href="#"
                        onClick={(e) => kategoriMenuAc(2)}
                        title={Language[languageStore].menuKurumsal}
                      >
                        {" "}
                        {Language[languageStore].menuKurumsal}
                      </Link>
                      <ul className="sub-menu" id="mkurumsalsub">
                        <li>
                          <Link
                            href="/kurumsal/abone-islemleri"
                            className="active"
                            onClick={() => menuClose()}
                            title={Language[languageStore].menuAboneIslemleri}
                          >
                            {" "}
                            {Language[languageStore].menuAboneIslemleri}
                          </Link>
                        </li>
                        <li>
                          <Link
                            href="/kurumsal/iptal-ve-iade-kosullari"
                            className="active"
                            onClick={() => menuClose()}
                            title={
                              Language[languageStore].menuIptalVeIadeKosullari
                            }
                          >
                            {" "}
                            {Language[languageStore].menuIptalVeIadeKosullari}
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            href="/kurumsal/filateli-dernek-federasyonu"
                            onClick={() => menuClose()}
                            className="active"
                            title={Language[languageStore].menuDernekFederasyon}
                          >
                            {" "}
                            {Language[languageStore].menuDernekFederasyon}
                          </Link>
                        </li>
                        <li>
                          <Link
                            href="/docs/2016_filateliterimler.pdf"
                            onClick={() => menuClose()}
                            className="active"
                            target="_blank"
                            title={Language[languageStore].menuFilateliTerimler}
                          >
                            {" "}
                            {Language[languageStore].menuFilateliTerimler}
                          </Link>
                        </li>
                        <li>
                          <Link
                            href="/kurumsal/filateli-egitim-seminerleri"
                            onClick={() => menuClose()}
                            className="active"
                            title={
                              Language[languageStore].menuFilateliEgitimSeminer
                            }
                          >
                            {" "}
                            {Language[languageStore].menuFilateliEgitimSeminer}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        href="/kurumsal/bize-ulasin"
                        className="active"
                        onClick={() => menuClose()}
                        title={Language[languageStore].menuBizeUlasin}
                      >
                        {" "}
                        {Language[languageStore].menuBizeUlasin}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-account">
                <div className="mobile-header-top">
                  <div className="user-account">
                    <a href="#">
                      <Image
                        src={Avatar}
                        width={50}
                        height={50}
                        alt="Kullanıcı Adı"
                      />
                    </a>
                    <div className="content">
                      <h6 className="user-name">
                        {Language[languageStore].merhaba}
                        <span className="text-brand">
                          {" "}
                          {isLoggin ? (
                            <>
                              {userBilgileri.ad}&nbsp;{userBilgileri.soyad}
                            </>
                          ) : (
                            Language[languageStore].ziyaretci
                          )}
                        </span>
                      </h6>
                      <p className="font-xs text-muted">
                        {Language[languageStore].filatelHosgeldiniz}
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="mobile-menu">
                  {isLoggin ? (
                    <>
                      {" "}
                      <li>
                        <Link
                          onClick={() => menuClose()}
                          href="/user/dashboard"
                        >
                          <i className="fa fa-solid fa-user"></i>&nbsp;
                          {Language[languageStore].menuKullaniciPaneli}
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => menuClose()} href="/user/orders">
                          <i className="fa fa-solid fa-heart"></i>&nbsp;
                          {Language[languageStore].menuSiparislerim}
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => menuClose()} href="/user/basket">
                          <i className="fa fa-solid fa-star"></i>&nbsp;
                          {Language[languageStore].menuSepetim}
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => menuClose()} href="/user/edit">
                          <i className="fa fa-solid fa-edit"></i>&nbsp;
                          {Language[languageStore].menuKullaniciBilgilerim}
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => menuClose()} href="/user/orders">
                          <i className="fa fa-solid fa-credit-card"></i>&nbsp;
                          {Language[languageStore].menuOdemeGecmisi}
                        </Link>
                      </li>
                      <li>
                        <a href="#" onClick={() => handleLogout()}>
                          <i className="fa fa-solid fa-lock"></i>&nbsp;
                          {Language[languageStore].menuCikisYap}
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link onClick={() => menuClose()} href="/login">
                          {Language[languageStore].menuGirisYap}
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => menuClose()} href="/register">
                          {Language[languageStore].menuKayitOl}
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
                <div className="mobile-account">
                  <div className="mobile-header-top">
                    <div className="user-account">
                      <div className="content">
                        <h6 className="user-name">
                          <span className="text-brand">
                            {" "}
                            {Language[languageStore].footerMenuBaglantilar}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <ul className="mobile-menu">
                    <li>
                      <a href="https://ptt.gov.tr">Ptt.gov.tr</a>
                    </li>
                  </ul>
                </div>
                <div className="mobile-app">
                  <a href="#">
                    <Image alt="PTT-Filateli" src={googlePlayImg} />
                  </a>
                  <a href="#">
                    <Image alt="PTT-Filateli" src={appStoreImg} />
                  </a>
                </div>
              </div>
              <div className="site-copyright color-gray-400 mt-30">
                Copyright 2023 © PTT A.Ş. Filateli Hizmetleri
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
