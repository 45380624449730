import { createSlice } from "@reduxjs/toolkit";
import { encryptJson, decrypt } from "../../libs/encryption";

const basket = createSlice({
  name: "basket",
  initialState: encryptJson(0),
  reducers: {
    incrementCartItemCount: (state) => {
      return encryptJson(state + 1);
    },
    decrementCartItemCount: (state) => {
      return encryptJson(state - 1);
    },
    decrementCartAllItemCount: (state, action) => {
      return encryptJson(action.payload);
    },
  },
});
export const {
  incrementCartItemCount,
  decrementCartItemCount,
  decrementCartAllItemCount,
} = basket.actions;
export default basket.reducer;
