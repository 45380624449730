// import store from "../redux";
// import { addProduct } from "../redux/stores/basket";
// export const addProductHandle = (product) => {
//   store.dispatch(addProduct(product));
// };

export function MetinKisalt({ text, maxLength }) {
  if (text != undefined) {
    if (text.length <= maxLength) {
      return <p>{text}</p>;
    }

    const kisaltilmisText = text.substr(0, maxLength - 3) + "...";

    return <p>{kisaltilmisText}</p>;
  }
  return <p>null</p>;
}
export const IsNullOrEmpty = (value) => {
  return (
    value === null || value === undefined || value.length === 0 || value === ""
  );
};
export function SeoUrlRegex(title) {
  if (title != null) {
    const replacements = {
      ı: "i",
      ğ: "g",
      ü: "u",
      ş: "s",
      ö: "o",
      ç: "c",
      İ: "I",
      Ğ: "G",
      Ü: "U",
      Ş: "S",
      Ö: "O",
      Ç: "C",
    };

    let seoUrl = title.toLowerCase();
    for (let key in replacements) {
      seoUrl = seoUrl.replace(new RegExp(key, "g"), replacements[key]);
    }
    seoUrl = seoUrl.replace(/\s+/g, "-");
    seoUrl = seoUrl.replace(/[^\w\-]+/g, "");

    return seoUrl;
  }
}
export function generateGuid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}
export function isInjectionValid(param) {
  if (typeof param !== "string") return false;

  // XSS saldırılarına karşı  bir kontrol
  if (param.includes("<script>") || param.includes("</script>")) return false;

  // SQL Injection saldırılarına karşı  bir kontrol
  const sqlInjectionPatterns = [
    "union",
    "select",
    "delete",
    "update",
    "insert",
    "--",
    "/*",
    "*/",
    "@@",
    "char",
    "nchar",
    "varchar",
    "nvarchar",
    "alter",
    "begin",
    "cast",
    "cursor",
    "declare",
    "drop",
    "exec",
    "fetch",
    "kill",
    "open",
    "sys",
    "sysobjects",
    "syscolumns",
  ];

  for (let pattern of sqlInjectionPatterns) {
    if (param.toLowerCase().includes(pattern)) return false;
  }

  return true;
}
