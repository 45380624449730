export const LanguageType = {
  TR: "tr",
  EN: "en",
};
export const SortByName = {
  TumUrunler: "0",
  AZGoreSirala: "1",
  EnYeniUrunler: "2",
  Filtrele: "3",
  UcuzdanPahaliya: "4",
  PahalidanUcuza: "5",
  EskiUrunler: "6",
};
