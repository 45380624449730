import Head from "next/head";

import Menu from "./menu";
import TopBar from "./top-bar";

const Header = () => {
  return (
    <>
      <TopBar />
      <Menu />
    </>
  );
};
export default Header;
